@import '~bootstrap/scss/functions';
@import 'abstracts/sass-variables';
@import '~bootstrap/scss/variables';
@import './../../../../sass/includes-admin/variables';

.bg-icon {
    color: white;
    padding: 1rem;
    border-radius: 999px;
    width: 70px;
    height: 70px;
    position: relative;

    &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    @each $color, $value in $theme-colors {
        &.bg-icon-#{$color} {
            background-color: shade-color($value, 20%);
            color: shade-color($white, 10%);

            &.bg-icon-hover:hover {
                background-color: shade-color($value, 40%);
                color: shade-color($white, 20%);
            }
        }
    }
}

.dashboard-block {
    position: relative;

    .dashboard-block-tools {
        position: absolute;
        z-index: 10;
        opacity: 0;
        transition: $transition-stack;
        right: 27px;
        top: 15px;
        padding: 2px 5px 0;
        border-radius: 3px;

        i {
            cursor: pointer;
        }
    }

    .dashboard-block-tool {
        margin: 0 5px;
        display: inline-block;

        i {
            color: $white;
            opacity: .65;
            transition: $transition-stack;
        }

        &:hover {
            opacity: 1;
        }
    }

    &:hover {
        .dashboard-block-tools {
            opacity: 1;
        }
    }
}

.icon-field {
    input {
        display: none;
    }

    label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        border-radius: 3px;
        background-color: $gray-900;
        height: 100%;
        border: 1px solid $gray-900;
        transition: $transition-stack;
    }

    input:checked + label {
        border: 1px solid $primary;
    }
}

.input-group {
    .clr-field {
        display: block;
        flex-grow: 1;
        border-top-right-radius: .2rem;
        border-bottom-right-radius: .2rem;
        overflow: hidden;

        input {
            border-radius: 0;
        }

        button {
            width: 48px;
            border-top-right-radius: .2rem;
            border-bottom-right-radius: .2rem;
            border-left: 1px solid #484e5d;
            right: 1px;
        }
    }
}
